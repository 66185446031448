<template>
  <div class="table-mundial-playoff">
    <template v-if="season == 1512"
      ><img class="img1-cont" src="https://golstatsimages.blob.core.windows.net/golstats-bets/brackets.png" />
      <img class="img2-cont" src="https://golstatsimages.blob.core.windows.net/golstats-bets/brackets_01.png" />
      <img class="img3-cont" src="https://golstatsimages.blob.core.windows.net/golstats-bets/brackes_03.png" />
      <img class="img4-cont" src="https://golstatsimages.blob.core.windows.net/golstats-bets/brackets_02@2x.png" />
    </template>
    <template v-if="season == 881">
      <img
        class="img1-cont"
        src="https://golstatsimages.blob.core.windows.net/golstats-bets/Brackets_rusia_2018_desktop.png"
      />
      <img class="img2-cont" src="https://golstatsimages.blob.core.windows.net/golstats-bets/brackets_rusia_01.png" />
      <img class="img3-cont" src="https://golstatsimages.blob.core.windows.net/golstats-bets/Brackets_rusia_2018.png" />
      <img class="img4-cont" src="https://golstatsimages.blob.core.windows.net/golstats-bets/brackets_rusia_02.png" />
    </template>
    <template v-if="season == 1784">
      <img class="img1-cont" src="https://golstatsimages.blob.core.windows.net/todoelfut/lc_brackets_567x488.png" />
      <img class="img2-cont" src="https://golstatsimages.blob.core.windows.net/todoelfut/lc_brackets_567x488-2.png" />
      <img class="img3-cont" src="https://golstatsimages.blob.core.windows.net/todoelfut/lc_brackets_567x488-1.png" />
      <img class="img4-cont" src="https://golstatsimages.blob.core.windows.net/todoelfut/lc_brackets_567x488-3.png" />
    </template>
  </div>
</template>
<script>
export default {
  name: 'TableMundialPlayOff',
  components: {},
  props: {
    season: {
      type: Number,
    },
  },
  watch: {},
  data() {
    return {};
  },
  async created() {},
  mounted() {},
  destroyed() {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.table-mundial-playoff {
  width: 100%;
  height: auto;
  padding: 30px;
  margin-top: 15px;
  @media screen and (max-width: 500px) {
    padding: 15px;
  }
}
.img1-cont {
  display: inline-block;
  @media screen and (max-width: 1220px) {
    display: none;
  }
  @media screen and (max-width: 970px) and (min-width: 630px) {
    display: inline-block;
  }
}
.img4-cont {
  display: none;
  @media screen and (max-width: 1079px) and (min-width: 970px) {
    display: inline-block;
  }
  @media screen and (max-width: 519px) and (min-width: 420px) {
    display: inline-block;
  }
}
.img2-cont {
  display: none;
  @media screen and (max-width: 1220px) and (min-width: 1080px) {
    display: inline-block;
  }
  @media screen and (max-width: 629px) and (min-width: 520px) {
    display: inline-block;
  }
}

.img3-cont {
  display: none;
  @media screen and (max-width: 420px) {
    display: inline-block;
  }
}
</style>
